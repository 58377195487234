.Form {
  max-width: 400px;
  position: relative;
  padding-right: 30px;

  @include respond-to("mobile") {
    //max-width:300px;
  }

  * {
    @extend %u-text--body;
  }
  .Form-field input,
  .Form-field textarea {
    background: transparent;
    border: 1px solid $base-color;
    padding: 8px 5px;
    display: block;
    width: 100%;

    &::placeholder {
      color: $base-placeholder-color;
      opacity: $base-placeholder-opacity;
    }
  }

  .Form-field textarea {
    resize: none;
    height: 7em;
  }

  .Form-field {
    padding-bottom: 3px;
  }
  .Form-field-title {
    padding-bottom: 10px;

    /*@include respond-to('tablet-up'){
			position:absolute;
			left:100%;
			bottom:0;
			width:100%;
			padding:10px;
			padding-left:30px;
		}*/
  }
  .Form-submit {
    display: block;
    @include responsive("padding-top", $base-grid-gutter);

    cursor: pointer;

    text-align: right;

    button {
      display: inline-block;
      border: 1px solid;
      text-align: center;
      min-width: 90px;
      padding: 0.1em 0.4em 0;
    }
  }
}

.Form--enquiry {
  @include respond-to("desktop") {
    max-width: 600px;
  }
  position: relative;
  padding: 30px;
  @include responsive-x(3, "padding-bottom", $base-padding);

  @include respond-to("mobile") {
    //max-width:300px;
  }

  transition: opacity 0.4s ease;

  &.Form--loading {
    opacity: 0.5;
    cursor: wait;
    * {
      pointer-events: none;
    }
  }
  * {
    @extend %u-text--body;
  }
  .Form-field input[type="text"],
  .Form-field input[type="email"],
  .Form-field textarea {
    background: transparent;
    border: 1px solid;
    display: block;
    width: 100%;

    &::placeholder {
      color: inherit;
      opacity: $base-placeholder-opacity;
    }
  }

  .Form-field-description .Content :first-child {
    padding-top: 16px;
  }
  .Form-field textarea {
    resize: none;
    height: 7em;
  }

  .Form-field input[type="checkbox"] {
    -webkit-appearance: checkbox;
    appearance: checkbox;
    opacity: 0;
  }

  .Form-field--checkbox {
    position: relative;
    padding-top: 10px;

    label {
      cursor: pointer;
      position: relative;
      display: inline-block;
      line-height: 20px;

      &:focus-within {
        &:before {
          border: 1px dotted;
        }
      }

      // Box
      &:before {
        content: "";
        display: inline-block;
        width: 20px;
        height: 20px;
        border: 1px solid;
        margin-right: 5px;
        vertical-align: middle;
      }
      .Form-field-label {
        display: inline-block;
        vertical-align: middle;
        line-height: 1;
        padding-top: 2px;
      }
      .Form-field-check {
        content: "";
        position: absolute;
        top: 7px;
        left: 3px;
        width: 15px;
        height: 5px;
        border-left: 1px solid;
        border-bottom: 1px solid;
        display: block;
        transform: rotate(-45deg);
        transition: opacity 0.4s ease;
        opacity: 0;
      }
      input {
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
      }
      input:checked ~ .Form-field-check {
        opacity: 1;
      }
    }
  }

  .Form-field input[type="radio"] {
    -webkit-appearance: radio;
    appearance: radio;
  }

  .Form-field {
    padding-bottom: 3px;
  }
  .Form-field-title {
    padding-bottom: 10px;

    /*@include respond-to('tablet-up'){
			position:absolute;
			left:100%;
			bottom:0;
			width:100%;
			padding:10px;
			padding-left:30px;
		}*/
  }
  .Form-submit {
    display: block;
    @include responsive-x(2, "margin-top", $base-grid-gutter);

    text-align: center;

    button {
      display: inline-block;
      border: 1px solid;
      text-align: center;
      min-width: 90px;
      padding: 0.5em;
      cursor: pointer;
    }
  }
  .Form-field--select {
    display: inline-block;
    width: 50%;
    padding-top: 5px;
    padding-right: 10px;

    .Form-field-description {
      width: 200%;
    }
  }

  .Form-field--select + .Form-field--select {
    padding-right: 0;
  }

  select {
    padding: 8px 5px;
    background: transparent;
    width: 100%;
    display: block;
    border-radius: 2px;
  }

  .Form-error {
    border: 1px dotted rgba(0, 0, 0, 0.5);
    padding: 5px;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 10px;
    animation: fadeIn 1s ease forwards;
  }
}

.Block--enquiry {
  .Block-content {
    margin: 0 auto;
    max-width: $base-max-width;
  }

  .Form {
    margin: 0 auto;
  }
  .Form-response {
    animation: fadeIn 1s ease forwards;

    @include responsive-x(1, "padding", $base-padding);

    background: rgba(255, 255, 255, 0.3);
    width: 80%;
    max-width: 500px;
    margin: 30px auto;
    margin-bottom: 60px;
    text-align: center;
    border-radius: 5px;
  }
}
