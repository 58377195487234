.Blocks {
  // Included templates within blocks hide their heading & body
  .Template {
    min-height: 0;
  }
  .Template-content {
    display: none;
  }
}
.Block {
  //border-top:2px solid;
}
.Block-media,
.Block-item {
  position: relative;
}
.Blocks-section--dark {
  color: $base-background;
  a {
    color: $base-background;
  }
}

/*

Masthead

*/

@keyframes growDown {
  0% {
    height: 0;
  }

  100% {
    height: 40%;
  }
}

.Block--masthead {
  position: relative;
  height: 100vh;
  overflow: hidden;

  .Block-screen {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 0;

    .Block-logo {
      svg rect,
      svg path,
      svg polygon {
        fill: $base-background;
      }

      position: absolute;

      top: 50%;
      transform: translate(-50%, -50%);
      left: 50%;
      width: 70%;
      max-width: 600px;
      opacity: 0.3;
      & > div {
        animation: fadeOut 1s 0.6s ease forwards;
      }
    }

    animation: fadeOut 3s 1.2s ease forwards;
  }

  .Block-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    clip: rect(0, auto, auto, 0);
    overflow: hidden;

    h1 {
      position: fixed;
      top: 50%;
      left: 0;
      width: 100%;
      transform: translateY(-50%);
      text-align: center;
      @extend %u-text--kol;
      color: $base-background;
      z-index: 1;
      text-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
      opacity: 0;
      animation: fadeIn 2s 0.8s ease forwards;
    }

    &:after {
      content: "";
      width: 4px;
      display: block;
      position: fixed;
      top: 60%;
      height: 0;
      left: 50%;
      background: url("/ui/img/textures/vertical-creme.png") top center
        no-repeat;
      background-size: 100% 50vh;
      transform: translateX(-50%);
      z-index: 1;
      animation: growDown 3s 1.5s ease forwards;
    }
  }
  .Block-media {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    z-index: 0;
    backface-visibility: hidden;
    //opacity:0.6;
  }

  [data-progress-marker] {
    height: 100%;
  }
}

/*

Divider

*/

.Block--divider {
  &:after {
    content: "";
    width: 4px;
    display: block;
    margin: 0 auto;
    background: url("/ui/img/textures/vertical-grey.png") top center no-repeat;
    background-size: 100% 50vh;
    height: 35vh;

    .Blocks-section--dark & {
      background-image: url("/ui/img/textures/vertical-creme.png");
    }
  }

  @include responsive-x(1, "padding-bottom", $base-padding);
}

/*

Item

*/

.Block--item {
  .Block-head {
    @include responsive("padding", $base-padding);
    @include responsive-x(1, "padding-top", $base-padding);
    text-align: center;
  }

  .Image {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }

  @include responsive-x(2, "padding-bottom", $base-padding);

  .Block-media,
  .Block-content {
    display: inline-block;
    vertical-align: middle;
    width: 100%;

    @include respond-to("tablet-up") {
      width: 50%;
    }
  }
  .Block-content {
    text-align: center;

    p {
      max-width: none;
    }

    .Content {
      max-width: 500px;
      margin: 0 auto;

      & > :last-child {
        padding-bottom: 0;
      }
    }

    @include responsive("padding", $base-padding);
  }

  &.Block--inset {
    .Block-media {
      @include responsive-x(2, "padding-left", $base-padding);
      @include responsive-x(2, "padding-right", $base-padding);
    }
  }
  &.Block--imageRight {
    direction: rtl;
    .Block-head,
    .Block-content {
      direction: ltr;
    }
  }
}

/*

Text

*/

.Block--text {
  .Block-head {
    @include responsive("padding", $base-padding);
    @include responsive-x(2, "padding-top", $base-padding);
    text-align: center;

    h1 {
      padding-bottom: 0;
    }
  }

  @include responsive("padding-left", $base-padding);
  @include responsive("padding-right", $base-padding);
  @include responsive("padding-bottom", $base-padding);

  .Blocks-section:first-child & {
    &:first-child {
      @include responsive("padding-top", $base-padding);
    }
  }

  .Block-content {
    margin: 0 auto;
    max-width: $base-max-width;
  }

  .Blocks--article & {
    .Block-content {
      max-width: none;
      margin: auto;
    }

    @include respond-to("tablet-up") {
      padding-left: 10vw;
      padding-right: 10vw;

      .Content {
        width: 60%;
        margin-left: auto;
        margin-right: auto;
      }

      &.Block--right {
        .Content {
          width: 50%;
          margin-left: auto;
          margin-right: 0;
        }
      }

      &.Block--left {
        .Content {
          width: 50%;
          margin-right: auto;
          margin-left: 0;
        }
      }
    }
  }
}

/*

Image

*/

.Block--image {
  @include responsive("padding-bottom", $base-padding);

  @include respond-to("tablet-up") {
    @include responsive("margin-bottom", $base-grid-gutter);
  }

  &.Block--half {
    @include responsive("padding-left", $base-padding);
    @include responsive("padding-right", $base-padding);
  }
  .Grid-item {
    @include responsive("padding-bottom", $base-grid-gutter);

    &:last-child {
      padding-bottom: 0 !important;
    }

    &[data-offset="offset"] {
      @include responsive-x(2, "padding-top", $base-padding);
    }
  }

  .Block-content {
  }

  .Grid {
    text-align: center;

    .Content {
      text-align: left;
    }
  }

  .Grid--full {
    .Content {
      @include responsive("padding-top", $base-grid-gutter);
      @include responsive("padding-bottom", $base-grid-gutter);
    }
  }

  .Grid--half {
    .Grid-item {
      width: 50%;
    }

    .Content {
      @include responsive("padding", $base-grid-gutter);
    }
  }

  @include respond-to("tablet-up") {
    @include responsive("padding-left", $base-padding);
    @include responsive("padding-right", $base-padding);
  }
}

/*

Form

*/

.Block--form {
  @include responsive("padding-left", $base-padding);
  @include responsive("padding-right", $base-padding);

  .Site-footer & {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

/*

Mosaic

*/

.Block--mosaic {
  @include responsive("padding-top", $base-grid-gutter);
  @include responsive-x(2, "padding-bottom", $base-grid-gutter);
  @extend %u-cf;

  .Block-section {
    @extend %u-grid;

    @include respond-to("tablet-up") {
      @include responsive("margin-bottom", $base-grid-gutter);
    }
  }

  .Block-item {
    @extend %u-gridItem;
    @include respond-to("mobile") {
      float: left;
      @include responsive("padding-bottom", $base-grid-gutter);
    }
    width: 50%;

    @include respond-to("tablet-up") {
      padding-bottom: 0;

      &.Block-item--twothirds {
        width: 2/3 * 100%;
      }

      &.Block-item--third {
        width: 1/3 * 100%;
      }
    }
  }

  @include respond-to("tablet-up") {
    @include responsive("padding", $base-padding);
  }
}

/*

Swiper

*/

.Block--swiper {
  .Block-content {
    @include responsive("padding", $base-padding);
  }
  .swiper-slide {
    width: auto;
  }

  .swiper-slide-shadow-left,
  .swiper-slide-shadow-right {
    height: auto !important;
    &:after {
      content: "";
      display: block;
      @include pad-ratio(5, 6);
    }
  }

  [data-swiper-slide-index] {
    //border: 5px solid transparent;
    .Item-media {
      //box-shadow:0 0 5px rgba(0,0,0,0.2);
    }
  }
  .swiper-container {
    padding-bottom: 50px;
  }

  .swiper-pagination {
    bottom: 5px;
    @extend %u-text--small;
  }

  .swiper-button-next {
    color: $base-color;
    bottom: 0;
    right: 45%;
    top: auto;
    width: 30px;
    height: 30px;

    margin: 0;
    &:after {
      display: none;
    }
    background: url("/ui/img/textures/button-right-grey.png") center center
      no-repeat;
    background-size: 50% auto;

    @include respond-to("tablet-down") {
      right: 40%;
    }
  }

  .swiper-button-prev {
    color: $base-color;
    bottom: 0;
    left: 45%;
    top: auto;
    width: 30px;
    height: 30px;

    margin: 0;
    background: url("/ui/img/textures/button-left-grey.png") center center
      no-repeat;
    background-size: 50% auto;

    &:after {
      display: none;
    }

    @include respond-to("tablet-down") {
      left: 40%;
    }
  }

  .swiper-scrollbar {
    display: none;
  }

  .Block-swiper {
    @include responsive("padding-bottom", $base-padding);
  }

  .Item {
    cursor: inherit;
    opacity: 0.7;
    transition: opacity 0.6s ease;
  }

  .swiper-slide-active .Item {
    cursor: pointer;
    &[data-href=""] {
      cursor: inherit;
    }
    opacity: 1;
  }

  @include responsive("padding-bottom", $base-padding);
}

/*

Gallery

*/

.Block--gallery {
  @include responsive("padding", $base-padding);
  .Block-media {
    margin: 0 auto;
    max-width: $base-max-width;
  }
}

/*

Arrangement

*/

.Block--arrangement {
  @include responsive("padding-top", $base-padding);

  .Block-scroll {
    height: 60vh;
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;

    &:after {
      content: "";
      display: inline-block;
      @include responsive("width", $base-padding);
    }

    @include responsive("padding-left", $base-padding);

    @include respond-to("tablet") {
      //padding-left:20vw;
    }
  }
  .Block-section {
    display: inline-block;
    width: auto;
  }

  .Block-content {
    @include responsive("padding", $base-padding);
  }
  .Block-media-inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 5px;
    bottom: 5px;
    @include responsive("bottom", $base-grid-gutter);
    @include responsive("right", $base-grid-gutter);
  }

  @include respond-to("desktop") {
    position: relative;

    .Block-content {
      padding-top: 30vh;
      padding-bottom: 30vh;
      margin-right: 50%;
      min-height: 100vh;
    }
    .Block-scroll-wrap {
      position: absolute;
      top: 40px;
      left: 0;
      right: 0;
      bottom: 0;
      left: 50%;
      & > * {
        position: sticky;
        height: calc(60vh + 40px);
        width: 100%;
        top: 20vh;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }
}

/*

Text With Image

*/

.Block--textWithImage {
  .Block-content {
    @include responsive("padding", $base-padding);
  }
  .Block-media {
    /* Inset:;
		@include responsive-x(2,'padding-top',$base-padding);
		@include responsive-x(2,'padding-left',$base-padding);
		@include responsive-x(2,'padding-right',$base-padding);
		*/
  }

  @include respond-to("desktop") {
    position: relative;

    .Block-content {
      @include responsive-x(2, "padding-top", $base-padding);
      margin-left: 50%;
      min-height: 100vh;
    }
    .Block-media {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 50%;
      & > * {
        position: sticky;
        height: 100vh;
        width: 100%;
        top: 0;
        left: 0;

        .Image {
          padding: 0 !important;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }
    }
  }
}

/*

Newsletter

*/

.Block--newsletter {
  .Block-content {
    margin: 0 auto;
    max-width: $base-max-width;
  }
}

.Block + .Template {
  .Block--enquiry {
    .Form:first-child {
      padding-top: 0;
    }
  }
}

.Block--enquiry {
  .Block-content {
    .Content * {
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
