%u-link--button {
  display: inline-block;
  padding: 0.2em 1.2em;
  border: 2px solid transparent !important;
  @extend %u-text--smallcaps;

  &:hover {
    border: 2px solid transparent !important;
  }

  position: relative;
  min-width: 100px;

  &:after,
  &:before {
    content: "";
    display: block;
    width: 101%;
    height: 105%;
    box-sizing: content-box;
    padding: 2px;
    //border:1px solid red;
    pointer-events: none;
    position: absolute;
  }

  &:before {
    top: -5px;
    left: -4px;
    background: url("/ui/img/textures/Frame_TL_Grey.png") top left no-repeat;
    background-size: 450px auto;
  }
  &:after {
    top: -1px;
    left: -1px;
    transform: rotate(180deg);
    //padding-right:3px;
    //box-sizing:content-box;
    background: url("/ui/img/textures/Frame_TL_Grey.png") top left no-repeat;
    background-size: 450px auto;
  }
}

%u-link--buttonGrey {
  &:before {
    background-image: url("/ui/img/textures/Frame_TL_Grey.png");
  }
  &:after {
    background-image: url("/ui/img/textures/Frame_TL_Grey.png");
  }
}

%u-link--buttonCreme {
  &:before {
    background-image: url("/ui/img/textures/Frame_TL_Cream.png");
  }
  &:after {
    background-image: url("/ui/img/textures/Frame_TL_Cream.png");
  }
}
