.Loader {
  display: none;
}

/*

Interface defaults

*/
[data-close] {
  @extend %u-text--body;
  position: absolute;
  z-index: 1;
  @include responsive("top", $base-interface-position);
  @include responsive("right", $base-interface-position);
}
[data-scroll-pane] {
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}

/*
	
Popup message

*/

.Site-message {
  .Site-message-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background: $menuBg;
    z-index: 99;
    padding: 20px;

    box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);

    padding-top: 70px;
    padding-bottom: 50px;

    width: 80%;
    max-width: 500px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);

    * {
      color: $base-color;
    }
  }

  .Site-message-close {
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 50%;

    width: 30px;
    height: 30px;
    cursor: pointer;
    opacity: 1;
    background: url("/ui/img/textures/cross-grey.png") center center no-repeat;
    background-size: 100% auto;
    .Icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0.8, 0.8);
      transform-origin: 50% 50%;
    }
  }

  transition: opacity 0.4s ease;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;

  .Content {
    text-align: center;
    margin: 0 auto;

    padding: 20px 0;
    margin: 0 40px;

    h1,
    h2,
    h3,
    h4,
    h5 {
      @extend %u-text--smallcaps;

      margin: 0;
      padding: 0;
      &:after {
        content: "";
        display: block;
        background: url("/ui/img/textures/Horizontal_Line_Master_Grey.png")
          center center no-repeat;
        background-size: cover;
        height: 3px;
        width: 100%;
        margin: 0 auto;
        margin-top: 0.5em;
      }
      padding-bottom: 1em;
    }

    p,
    li {
      @extend %u-text--small;
      margin: 0;
      padding: 0;
      padding-bottom: 1em;
    }
  }

  .Site--noMessage & {
    opacity: 0;
    pointer-events: none;
  }

  .Site-message-bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: red;
    z-index: 88;
    background: rgba($brown, 0.7);
  }
}

/*

Burger

*/
.Site-burger {
  @extend %u-text--body;
  position: fixed;

  @include responsive-x(0.75, "top", $base-interface-position);
  @include responsive("left", $base-interface-position);

  z-index: 6;
  cursor: pointer;

  transition: opacity 0.6s ease;

  .Burger {
    width: 32px;
    height: 32px;

    &:before,
    &:after {
      line-height: $base-icon-size;
      transition: opacity 0.4s ease;
      display: block;

      width: 32px;
      height: 32px;
    }
    &:before {
      content: "";
      background: url("/ui/img/textures/burger-grey.png") center center
        no-repeat;
      background-size: 100% auto;
    }
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;

      background: url("/ui/img/textures/cross-grey.png") center center no-repeat;
      background-size: 100% auto;
    }
    .Site--dark & {
      &:before {
        background-image: url("/ui/img/textures/burger-creme.png");
      }
    }
    .Site--article & {
      &:before {
        background-image: url("/ui/img/textures/burger-grey.png");
      }
    }
    .Site--menu & {
      &:before {
        opacity: 0;
        &:before {
          background-image: url("/ui/img/textures/burger-grey.png");
        }
      }

      &:after {
        opacity: 1;
      }
    }
  }
}

/*

Header Nav

*/
.Site-header-nav {
  text-align: right;

  & > * {
    display: inline-block;
    text-align: left;
    vertical-align: middle;

    @include respond-to("tablet-up") {
      margin-left: 5px;
    }
  }
  @extend %u-text--body;
  position: fixed;

  @include responsive-x(0.75, "top", $base-interface-position);
  @include responsive-x(1, "right", $base-interface-position);
  z-index: 4;

  @include respond-to("mobile") {
    bottom: 0;
    left: 0;
    right: 0;
    background: $base-background;
    background: linear-gradient(
      180deg,
      rgba($base-background, 0) 0%,
      rgba($base-background, 1) 100%
    );
    top: auto;
    text-align: center;
    padding: 10px;
    padding-top: 40px;
    padding-bottom: 20px;
    .Html--dark & {
      background: rgba(0, 0, 0, 0.5);
      background: linear-gradient(
        180deg,
        rgba($brown, 0) 0%,
        rgba($brown, 0.5) 100%
      );
    }
  }

  .Icon:before {
    color: $base-color;

    .Site--dark & {
      color: $base-background;
    }
  }
}

/*

Shortcuts Nav

*/
.Site-shortcuts-nav {
  @extend %u-text--body;
  position: fixed;

  @include responsive-x(0.75, "top", $base-interface-position);
  @include responsive-x(2, "left", $base-interface-position);

  z-index: 5;

  @include respond-to("mobile") {
    @include responsive-x(1, "top", $base-interface-position);
    @include responsive-x(0.75, "right", $base-interface-position);
    text-align: right;
  }
}

/*

Logo

*/

.Site-logo {
  position: fixed;
  @include responsive("top", $base-interface-position);
  @include responsive("left", $base-interface-position);
  z-index: 2;
  width: 50px;
  height: 40px;

  a {
    display: block;
  }

  transition: opacity 0.6s ease;

  .Site--scrolled & {
    opacity: 0;
  }

  svg path,
  svg plygon {
    fill: $base-color;
  }

  display: none;
}

/*

Menu

*/

.Site-menu {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  background: rgba($menuBg, 0.9);
  backdrop-filter: blur(3px);
  pointer-events: none;
  opacity: 1;
  transform: translate3d(0, 100%, 0);
  transition: transform 0.6s ease;

  .List--nav {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  .Site--menu & {
    transition: transform 1.2s $easeOutExpo;
    transform: translate3d(0, 0, 0);
    pointer-events: all;
  }
}

/*

Overlay

*/

.Site-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.6s ease;
  backface-visibility: hidden;

  .Site-overlay-content {
    background: $menuBg;
    border-bottom: 0px solid transparent; // Stops margin collapse

    .swiper-slide {
      width: auto;
    }
  }

  .swiper-pagination,
  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }

  [data-screen] {
    height: 100vh;
    background: $menuBg;
    background: black;
  }
  .Site--overlay & {
    transition: opacity 0.6s ease;
    opacity: 1;
    pointer-events: all;
  }
}

/*

	Main

*/

.Site-main {
}

/*

Panel

*/

.Site-panel {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  background: $menuBg;
  pointer-events: none;
  opacity: 1;
  transform: translate3d(100%, 0, 0);
  transition: transform 0.6s ease;
  backface-visibility: hidden;

  @include respond-to("tablet") {
    left: 33%;
  }

  @include respond-to("desktop") {
    left: 50%;
  }

  .Blocks-section:first-child {
    min-height: 100vh;
    .Block:first-child {
      @include responsive-x(2, "padding-top", $base-padding);
    }
  }

  .Site-panel-close {
    cursor: pointer;
  }
  .Site--panel & {
    transition: transform 1.2s $easeOutExpo;
    transform: translate3d(0, 0, 0);
    pointer-events: all;
  }
}

/*

Article

*/

.Site-article {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.6s ease;
  backface-visibility: hidden;

  .Site-article-content {
    background: $menuBg;
    min-height: 100vh;
  }

  [data-screen] {
    height: 100vh;
    background: $menuBg;
    background: black;

    @include respond-to("mobile") {
      height: 90vh;
      opacity: 0.9;
    }
  }
  .Site--article & {
    transition: opacity 0.6s ease;
    opacity: 1;
    pointer-events: all;
  }

  .Site-article-head {
    @include responsive-x(2, "padding-top", $base-padding);
    @include responsive("padding-left", $base-padding);
    @include responsive("padding-right", $base-padding);
    @include responsive("padding-bottom", $base-padding);
    opacity: 0.5;

    @include respond-to("tablet-up") {
      padding-left: 10vw;
    }
  }
  .Site-article-close {
    padding: 0 0.5em;
    padding-top: 2px;
    line-height: 150%;
    border-radius: 2em;

    @include respond-to("mobile") {
      display: none;
    }
  }
}

/*

Footer

*/

.Site-footer {
  text-align: center;
  background: $beige-tint;
  .Content p {
    max-width: none;
  }

  @include respond-to("mobile") {
    padding-bottom: 70px; // Allow for toolbar
  }
}

.Site-footer-content {
  @include responsive("padding-top", $base-padding);
  @include responsive("padding-bottom", $base-padding);
}

.Site-footer-contact {
  @include responsive("padding", $base-padding);
}

.Site-footer-foot {
}
.Site-footer-nav {
}

.Site-footer-credit {
  @include responsive-x(0.25, "padding-top", $base-padding);
}
