$i-scale: 1.25;
.Icon--glyph-facebook {
  width: $base-icon-size * $i-scale;
  height: $base-icon-size * $i-scale;
  line-height: $base-icon-size * $i-scale;
  &:before {
    color: transparent !important;
    background: url("/ui/img/FB-grey.png") center center no-repeat;
    background-size: 100% auto;
    width: $base-icon-size * $i-scale;
    height: $base-icon-size * $i-scale;
    line-height: $base-icon-size * $i-scale;

    .Site--dark .Site-header-nav & {
      background-image: url("/ui/img/FB-cream.png");
    }
  }
}

.Icon--glyph-instagram {
  width: $base-icon-size * $i-scale;
  height: $base-icon-size * $i-scale;
  line-height: $base-icon-size * $i-scale;
  &:before {
    color: transparent !important;
    background: url("/ui/img/INST-grey.png") center center no-repeat;
    background-size: 100% auto;
    width: $base-icon-size * $i-scale;
    height: $base-icon-size * $i-scale;
    line-height: $base-icon-size * $i-scale;

    .Site--dark .Site-header-nav & {
      background-image: url("/ui/img/INST-cream.png");
    }
  }
}
