@keyframes spinLeft {
  100% {
    transform: translateX(-50%);
  }
}
@keyframes mexico {
  100% {
    transform: translateX(0%);
  }
}

@keyframes britain {
  100% {
    transform: translateX(-10%);
  }
}

@keyframes europe {
  100% {
    transform: translateX(-20%);
  }
}

@keyframes spinRight {
  0% {
    transform: translateX(-50%);
  }

  100% {
    transform: translateX(0%);
  }
}
.Approach {
  position: relative;
  min-height: 110vh;
  @include responsive("padding", $base-padding);

  .Content {
    width: 80%;
    margin: 0 auto;
    text-align: left;

    p:first-child {
      @extend %u-text--subheading;
    }
  }
}

.Approach-back {
  width: 80%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -175%);

  max-width: 600px;

  //@include responsive('padding-bottom',$base-padding);
  //@include responsive('padding-right',$base-padding);
  text-align: right;
  //@include responsive-x(-2.5,'margin-top',$base-padding);
  a {
    @extend %u-text--smallcaps;
    padding-left: 30px;
    line-height: 30px;
    display: inline-block;
    opacity: 0.5;
    text-align: left;
    margin: 0;
    background: url("/ui/img/textures/button-left-grey.png") left center
      no-repeat;
    background-size: 15px auto;
    .Html--no-touch & {
      &:hover {
        opacity: 1;
      }
    }
  }
}
.Approach-media {
  @extend %u-valign-children;
  text-align: center;
}

.Approach-body {
  text-align: center;
  @include responsive-x(2, "padding-top", $base-padding);
  &:before {
    min-height: 45vh;
  }
  & > * {
    vertical-align: middle;
  }
}

.Approach-title {
  @extend %u-text--heading;
  text-align: center;
  text-align: center;

  @include responsive-x(2, "padding-top", $base-padding);
  @include responsive-x(2, "padding-bottom", $base-padding);

  .Blocks & {
    padding-top: 0;
  }
}

@include respond-to("desktop") {
  .Approach-media {
    height: 60vh;
    display: inline-block;
    position: sticky;
    top: 20vh;
    left: 0;
    width: 50%;
    vertical-align: middle;
  }

  .Approach-body {
    //min-height:100vh;
    //background:red;
    width: 50%;
    display: inline-block;
    text-align: center;
    padding-top: 15vh;
    position: relative;
    vertical-align: top;
    position: relative;
  }

  .Approach-content {
    margin-top: -10vh;
    position: relative;

    &:before {
      display: block;
      content: "";
      background: url("/ui/img/textures/Horizontal_Line_Master_Grey.png") top
        center no-repeat;
      background-size: cover;
      height: 3px;
      margin: 0 auto;
      //max-width:600px;
      width: 80%;
      opacity: 1;
      @include responsive-x(2, "margin-top", $base-padding);
      @include responsive-x(0.5, "margin-bottom", $base-padding);
    }
  }
}

.Approach-map {
  position: relative;
  width: 80%;
  max-width: 60vh;
  margin: 0 auto;
  //border:1px solid rgba(0,0,0,0.05);
  border-radius: 50%;
  overflow: hidden;
  clip-path: circle(50%);

  //transform:scale3d(1.1,1.1,1);
  transition: opacity 1s 0.1s $easeOutExpo, transform 1s 0.1s $easeOutExpo;

  .Approach--default & {
    transform: scale3d(1, 1, 1);
  }

  .Approach--zoomed & {
    transform: scale3d(1.2, 1.2, 1);
    opacity: 0;
  }

  &:after {
    content: "";
    display: block;
    padding-top: 100%;
    background: radial-gradient(
      circle,
      rgba(255, 255, 255, 0.05) 50%,
      rgba($beige-tint, 0.7) 100%
    );
    //opacity:0;
  }

  .Approach-map-bg,
  .Approach-map-reverse {
    position: absolute;
    top: 0;
    left: 0;
    width: 400%;
    height: 100%;

    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background: url("/ui/img/map/map-bg.png") center left repeat-x;
      background-size: 50% auto;
      z-index: 2;
    }
  }

  .Approach-map-bg {
  }
  .Approach-map-reverse {
    &:after {
      background-image: url("/ui/img/map/map-bg-reverse.png");
      background-size: 50% auto;
    }
    opacity: 0.08;
    //animation:spinRight 30s linear infinite;
  }
}

.Approach-highlight {
  transition: opacity 0.6s ease;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: center left repeat-x;
  background-size: 50% auto;
  z-index: -1;
  opacity: 0;
  &.Approach-highlight--mexico {
    background-image: url("/ui/img/map/mexico.png");

    .Approach--mexico & {
      opacity: 0.6;
    }
  }

  &.Approach-highlight--britain {
    background-image: url("/ui/img/map/britain.png");
    .Approach--britain & {
      opacity: 0.6;
    }
  }

  &.Approach-highlight--europe {
    background-image: url("/ui/img/map/eastern-europe.png");
    .Approach--europe & {
      opacity: 0.6;
    }
  }
}

.Approach-svg {
  position: absolute;
  left: 0;
  width: 100%;
  top: 50%;
  transform: translateY(-50%) scale(0.9);
  opacity: 0;
  transition: opacity 1s ease, transform 1s ease;
  pointer-events: none;

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    top: 0;
    background-size: 100% auto;
    pointer-events: none;
  }

  &:after {
    content: "Click a region on the map to explore";
    @extend %u-text--small;
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    display: block;
    opacity: 0;
  }
  &.Approach-svg--europe {
    &:before {
      background-image: url("/ui/img/map/zoom-eastern-europe.png");
    }
    .Approach--zoom-europe & {
      opacity: 1;
      transform: translateY(-50%) scale(1);
      pointer-events: all;

      &:after {
        animation: pulse 5s 1s ease;
      }
    }
  }
  &.Approach-svg--britain {
    &:before {
      background-image: url("/ui/img/map/zoom-britain.png");
    }
    .Approach--zoom-britain & {
      opacity: 1;
      transform: translateY(-50%) scale(1);
      pointer-events: all;

      &:after {
        animation: pulse 5s 1s ease;
      }
    }
  }
  &.Approach-svg--mexico {
    &:before {
      background-image: url("/ui/img/map/zoom-mexico.png");
    }
    .Approach--zoom-mexico & {
      opacity: 1;
      transform: translateY(-50%) scale(1);
      pointer-events: all;

      &:after {
        animation: pulse 5s 1s ease;
      }
    }
  }
  path {
    fill: #efaa33;
    transition: opacity 0.6s ease;
    opacity: 0.4;
  }

  path.is-active {
    opacity: 1;
  }

  g.is-active {
    path {
      opacity: 1;
    }
  }
}
