%u-text--body {
  @include font-size($base-font-size);

  @include respond-to("mobile") {
    @include font-size(14px);
  }

  @include respond-to("wide") {
    @include font-size(18px);
  }
}

%u-text--heading {
  text-transform: uppercase;
  @include font-size(24px);
  line-height: 1.4;

  @include respond-to("mobile") {
    @include font-size(16px);
  }

  letter-spacing: 0.1em;
}

%u-text--intro {
  font-size: 2.5vw;
  white-space: nowrap;

  @include respond-to("wide") {
    @include font-size(28px);
  }

  @include respond-to("mobile") {
    font-size: 6vw;
  }
}
%u-text--kol {
  font-family: $base-font-family-alt;
  font-size: 2.5vw;
  white-space: nowrap;
  letter-spacing: 0.02em;
  @include respond-to("wide") {
    @include font-size(28px);
  }

  @include respond-to("mobile") {
    font-size: 6vw;
  }
}

%u-text--subheading {
  @extend %u-text--body;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: normal;
}

%u-text--bigcaps {
  @extend %u-text--heading;
}

%u-text--smallcaps {
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1.8;
  letter-spacing: 0.1em;

  @include respond-to("mobile") {
    @include font-size(10px);
  }
}

%u-text--small {
  font-size: 13px;

  @include respond-to("mobile") {
    @include font-size(11px);
  }
}
