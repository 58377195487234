.Cookie-toggle {
  cursor: pointer;
  display: inline-block;
  padding: 3px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  position: relative;
  border-radius: 10px;
  height: 20px;
  vertical-align: middle;
  background: #dddddd;
  width: 40px;
  transition: background 0.4s ease;
  margin-right: 170px;
}

.Cookie-toggle::after {
  content: "";
  display: block;
  position: absolute;
  height: 14px;
  width: 14px;
  background: #333;
  top: 2px;
  left: 2px;
  border-radius: 50%;
  opacity: 0.5;
  transition: all 0.3s ease;
}

.Cookie-toggle.is-active {
  background: #2fd265;
  border-color: rgba(0, 0, 0, 0.2);
}

.Cookie-toggle.is-active::after {
  background: white;
  opacity: 1;
  left: 100%;
  margin-left: -16px;
}

.Cookie-toggle-label {
  position: absolute;
  font-size: 10px;
  left: 100%;
  top: 0;
  line-height: 20px;
  height: 20px;
  display: inline-block;
  padding: 0 5px;
  width: 200px;
}

.Cookie-manager {
  max-width: 800px;
  padding: 20px;
  margin: 40px auto;
}

.Cookie-manager h1 {
  margin-bottom: 1em;
  font-size: 32px;
}

.Cookie-manager h2 {
  margin-bottom: 1em;
  font-size: 24px;
}

.Cookie-manager p {
  margin-bottom: 1em;
  font-size: 14px;
}

.Cookie-manager p a {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  opacity: 1;
  transition: border 0.4s ease;
}

.Cookie-manager p a:hover {
  border-color: transparent;
}

.Cookie-manager ul {
  margin-bottom: 2em;
  font-size: 14px;
  padding-left: 1em;
  margin-left: 2em;
  list-style-type: circle;
}

.Cookie-table {
  border-top: 2px solid rgba(0, 0, 0, 0.3);
  width: 100%;
  text-align: left;
  margin-top: 40px;
  margin-bottom: 40px;
}

.Cookie-table th,
.Cookie-table td {
  padding: 5px;
  font-size: 14px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 50%;
}

@media only screen and (max-width: 600px) {
  .Cookie-manager h1 {
    font-size: 24px;
  }
  .Cookie-manager h2 {
    font-size: 18px;
  }
  .Cookie-manager p,
  .Cookie-manager ul,
  .Cookie-manager th,
  .Cookie-manager td {
    font-size: 12px;
  }
}

.Cookie-table--auto th,
.Cookie-table--auto td {
  width: auto;
}

.Cookie-table th {
  font-weight: bold;
}

.Cookie-table tr:last-child td {
  border-bottom: none;
}

.Cookie-section {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 10px;
  /*border:1px dotted rgba(0,0,0,0.2);*/
  margin-bottom: 30px;
  margin-top: 25px;
}

.Cookie-message {
  position: fixed;
  z-index: 5;
  bottom: 20px;
  right: 20px;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  max-width: 510px;
  transition: opacity 0.5s ease;
  opacity: 0;
  pointer-events: none;
}

.Cookie-message.is-active {
  opacity: 1;
  pointer-events: all;
}

.Cookie-message {
  padding: 15px;
  margin: 15px;
  border-radius: 5px;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.1);
}

.Cookie-message * {
  font-size: 12px;
}

@media only screen and (max-width: 600px) {
  .Cookie-message {
    max-width: none;
    right: 0px;
    left: 0px;
  }
  .Cookie-message * {
    font-size: 11px;
  }
}

.Cookie-message p {
  margin-bottom: 1.2em;
}

.Cookie-message h5 {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 0.5em;
}

.Cookie-message-accept,
.Cookie-message-decline,
.Cookie-message-details {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  margin-bottom: 5px;
}

.Cookie-message-accept,
.Cookie-message-decline {
  padding: 7px 10px;
  background: #fff;
  color: #222;
  min-width: 80px;
  border-radius: 2px;
  border: 1px solid transparent;
  text-align: center;
  cursor: pointer;
  transition: all 0.4s ease;
  min-width: 150px;
}

.Cookie-message-decline {
  /*background:#666;
    color:#FFF;*/
  background: transparent;
  color: white;
  border: 1px solid white;
}

.Cookie-message-decline:hover {
  background: #fb5e3a;
  color: white;
  border-color: #fb5e3a;
}

.Cookie-message-accept:hover {
  background: #2fd265;
  color: white;
}

.Cookie-message-details {
  margin-left: 10px;
  text-decoration: underline;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.4s ease;
  margin-bottom: 0;
  padding-left: 0;
  margin-left: 0;
}

.Cookie-message-details:hover {
  opacity: 0.7;
}
