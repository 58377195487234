@font-face {
  font-family: "clearface";
  src: url("./clearface-regular.woff2") format("woff2"),
    url("./clearface-regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "clearface";
  src: url("./clearface-heavy.woff2") format("woff2"),
    url("./clearface-heavy.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "clearface";
  src: url("./clearface-bold.woff2") format("woff2"),
    url("./clearface-bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
