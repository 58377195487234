%u-layout {
  position: relative;
  z-index: 1;
}

%u-layout--window {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: 1;

  /* http://stephen.io/mediaqueries/ */
  /* Viewport height fix in pure css for specific iOS devices */
  $chrome: 107px;

  /* iPhone 2G-4 portrait */
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) {
    height: 480px - $chrome;
  }
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: landscape) {
    height: 320px;
  }

  /* iPhone 5,5s portrait */
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (orientation: portrait) {
    height: 568px - $chrome;
  }
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (orientation: landscape) {
    height: 320px;
  }

  /* iPhone 6,7,8  */
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) {
    height: 667px - $chrome;
  }
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: landscape) {
    height: 375px;
  }

  /* iPhone 6+,7+,8+ */
  @media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (orientation: portrait) {
    height: 736px - $chrome;
  }
  @media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (orientation: landscape) {
    height: 414px;
  }

  /* iPhone X */
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    height: 812px - $chrome;
  }
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
    height: 375px;
  }

  /* iPad */

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    height: 927px; // From: https://stackoverflow.com/questions/2880520/how-large-is-the-usable-area-in-ipad-safari?utm_medium=organic&utm_source=google_rich_qa&utm_campaign=google_rich_qa
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    height: 671px; // From: https://stackoverflow.com/questions/2880520/how-large-is-the-usable-area-in-ipad-safari?utm_medium=organic&utm_source=google_rich_qa&utm_campaign=google_rich_qa
  }
}

%u-layout--fluid {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

%u-layout--abs {
  position: absolute;
  width: 100%;
  z-index: 1;
}

%u-layout--fixed {
  position: fixed;
  width: 100%;
  z-index: 1;
}

%u-layout-head {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
}

%u-layout-body {
  position: relative;
  z-index: 1;
}

%u-layout-fill {
  @extend %u-box--fluid;
  z-index: 1;
}

%u-layout-centre {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}

%u-layout-foot {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 3;
}

%u-layout-right {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 2;
}

%u-layout-middle {
  height: 100%;

  @extend %u-valign-children;
}

%u-layout-scroll {
  @include scrollable;
}

%u-layout-left {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 2;
}

%u-layout-tl {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
}

%u-layout-tr {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
}

%u-layout-tm {
  position: absolute;
  left: 50%;
  top: 0;
  z-index: 3;
  transform: translateX(-50%);
}

%u-layout-bm {
  position: absolute;
  left: 50%;
  bottom: 0;
  z-index: 3;
  transform: translateX(-50%);
}

%u-layout-lm {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 3;
  transform: translateY(-50%);
}

%u-layout-rm {
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 3;
  transform: translateY(-50%);
}

%u-layout-container {
  @extend %u-container;
}

%u-layout-bl {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 3;
}

%u-layout-br {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 3;
}

%u-layout-bg {
  @extend %u-box--fluid;
  z-index: 0;
}
